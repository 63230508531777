/* eslint-disable no-unused-expressions */
import React , {useContext} from 'react';
import { useHistory } from "react-router-dom"
import { useRouteMatch } from "react-router";
import MicroFrontend from '@utils/MicroFrontend';
import useKeycloak from '@containers/keycloak/keycloak';
import AppContext from '@context/app/AppContext';

const Manage = () => {
    const history = useHistory()
    const { globalConfigData  , selectedApp } = useContext(AppContext);
    const tileStatus = globalConfigData?.data?.find(
        (item) => item?.name === "manage_tiles"
    )?.value;
    return (
        <div style={{display:"flex",height:"100%",width:"100%",flexDirection:"column"}}>
            <div className="c-manage__header">
                <h6 className="c-manage__title">
                    Select the workflow to get started
                </h6>
                <p className="c-manage__info">
                    <b>
                        Welcome! Explain applications, enhance existing
                        code, conduct root cause analysis, with
                        trainings and documentation support!
                    </b>{' '}
                </p>
            </div>
            <div style={{display:"grid",
                gridTemplateColumns: "100% 100%",
                gridGap: "40px 20px",
                width:"60%",flexDirection:"column",padding:"0 10%",alignItems:"center"}}>
                {process.env.REACT_APP_DEFAULT_REALM && <MicroNav tileStatus={tileStatus} history={history} globalConfigData={globalConfigData} selectedApp={selectedApp}/>}
            </div>
        </div>
    );
};

export default Manage;

const MicroNav = ({ history , globalConfigData , selectedApp, tileStatus}) => {
    const keycloakDetails = useKeycloak()
    const ROOT_PATH = process.env.PUBLIC_URL
    const realm = process.env.REACT_APP_DEFAULT_REALM
    const PLATFORM_URL = process.env.REACT_APP_PLATFORM_URL;

    console.log(ROOT_PATH , realm , 'checkkkkkkkkkkkkkkkkkkkkkkkk first')
// console.log(EXTERNAL_LINKS[key].appKey.toUpperCase(),'EXTERNAL_LINKS[key].appKey.toUpperCase()')
    return (<>
        {tileStatus?.map((childApp, i) =>
            childApp.hidden !== true && 
            <>
                {childApp.enabled === true ? <MicroFrontend history={history}
                    useKeycloak={keycloakDetails}
                    key={childApp.title}
                    host={PLATFORM_URL+"/"+childApp.appKey.toUpperCase()}
                    appKey={childApp.appKey.toUpperCase()}
                    name={childApp.appKey.toUpperCase()}
                    rootUrl={`${ROOT_PATH}/${realm}`}
                    menuName={childApp.title} 
                    globalConfigData={globalConfigData}
                    selectedApp={selectedApp}
                    /> : 
                    <div class="c-manage-card" style={{pointerEvents:"none",background:"lightgray"}}>
                        <div class="c-manage-card__wrapper" style={{paddingLeft:"2rem"}}>
                            <a href="javascript:;" class="c-manage-card__header -stretch-link" style={{color:"gray"}}>{childApp.title}</a>
                            <div class="c-manage-card__body"></div>
                        </div>
                    </div>
                    }
            </>
        )}
    </>)
}